@import '../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
  padding-bottom: 70px;
}

.menuLink {
  composes: h4 from global;
  position: relative;
  font-weight: var(--fontWeightRegular);
  display: block;
  cursor: pointer;

  /* Dimensions */
  width: 100%;
  margin: 0 0 5px 0;
  font-size: 18px;
  /* padding: 8px 0px; */

  /* Layout details */
  color: var(--colorBlack);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.needSubscribe {
  margin-top: 50px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .menuLink {
    padding: 36px 0;
    font-size: 18px;
    font-weight: var(--fontWeightRegular);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--colorBlack);
    text-decoration: none;
    line-height: 100%;

    @media (min-width: 414px) {
      min-width: 150px;
    }

    &:hover {
      color: var(--marketplaceColor);
    }

    &:not(:last-child) {
      margin-right: 24px;
    }

    &.signupLink {
      padding: 12px 24px;
      color: var(--colorWhite);
    }

    &.loginLink {
      padding: 12px 24px;
      background-color: var(--bgLight);

      &:hover {
        background-color: var(--colorBlack);
        color: var(--colorWhite);
      }
    }
  }
}

.signupLink,
.loginLink {
  composes: buttonDefault from global;
  min-width: auto;
  width: auto;
  line-height: 100%;
  min-height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 50px;
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.greeting {
  /* Font */
  font-weight: var(--fontWeightBlack);
  font-size: 24px;
  line-height: 24px;

  padding: 3px 0 3px 0;
  margin-bottom: 1px;
  margin-top: 16px;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 56px;
    padding: 5px 0 3px 0;
    /* x * 8px */
    margin-top: 24px;
  }
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;

  color: var(--colorGrey300);

  /* Position component */
  width: initial;
  margin: 4px 0 24px 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    margin: 0 0 36px 0;
  }
}

.customLinkFont {
  /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 21px;
  line-height: 24px;

  padding: 4px 0 2px 0;
  /* x * 6px */
  margin-top: 24px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 40px;
    padding: 1px 0 7px 0;
  }
}

.inbox {
  /* Font */
  composes: customLinkFont;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
}

.navigationLink {
  /* Font */
  composes: customLinkFont;
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
}

.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: buttonPrimary from global;
}

.authenticationGreeting {
  /* Font */
  composes: h1 from global;

  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}

.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
  color: var(--colorBlack);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.spacer {
  width: 100%;
  height: 124px;
}

.createListing {
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightBold);
  font-size: 21px;
  line-height: 24px;
  margin-top: 5px;
}

.createListings {
  margin-top: 15px;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightBold);
  font-size: 21px;
  line-height: 24px;
  padding-bottom: 100px;
}

.completeProfile {
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightBold);
  font-size: 21px;
  line-height: 24px;
  margin-top: 5px;
  margin-bottom: 11px;
}