@import '../../styles/customMediaQueries.css';

.root {

  /* Layout */
  /* display: flex;
  flex-direction: column;
  transition: var(--transitionStyleButton);
  box-shadow: var(--boxShadowButton);

  &:hover {
    transition: var(--transitionStyleButton);
    text-decoration: none;
    transform: scale(1.02);
    box-shadow: var(--boxShadowButton);
  } */
  &:hover {
    text-decoration: none;
  }
}

.listingCardWrapper {
  display: flex;
  flex-direction: column;
  transition: var(--transitionStyleButton);
  box-shadow: var(--boxShadowButton);
  /* padding: 40px 24px; */

  &:hover {
    transition: var(--transitionStyleButton);
    text-decoration: none;
    transform: scale(1.02);
    box-shadow: var(--boxShadowButton);
  }
}

.listingCardWrapperModeling {
  display: flex;
  flex-direction: column;
  transition: var(--transitionStyleButton);
  box-shadow: var(--boxShadowButton);
  padding: 40px 24px;

  &:hover {
    transition: var(--transitionStyleButton);
    text-decoration: none;
    transform: scale(1.02);
    box-shadow: var(--boxShadowButton);
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100);
  /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {}
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px;
}

.jobProfileInfo {
  display: flex;
  flex-direction: column;

  &>div {
    padding: 20px;
  }
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: h4 from global;
  font-weight: var(--fontWeightBold);
  line-height: 110%;
  /* Remove default margins from font */
  margin: 0 0 5px 0;
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: h4 from global;
  line-height: 110%;
  text-transform: capitalize;
  font-weight: var(--fontWeightBold);
  color: var(--colorBlack);
  margin: 0 0 18px 0;
  font-size: 24px;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightRegular);
  color: var(--colorGrey700);
  font-size: 14px;
  line-height: 130%;
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: 65px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 0;
  }
}

.authorAddress {
  font-weight: var(--fontWeightRegular);
  color: var(--colorGrey700);
  font-size: 14px;
  line-height: 130%;
  padding-top: 4px;
  padding-bottom: 4px;

  & span {
    padding-left: 10px;
  }
}

.applyButton {
  width: fit-content;
  background-color: var(--marketplaceColor);
  color: #fff;
  padding: 6px 18px;
  border-radius: 45px;
  cursor: pointer;
  margin-top: 18px;

  &:hover {
    text-decoration: none;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.topBarTitle {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;

  & .verifiedIcon {

    & svg {
      fill: rgb(0, 166, 255);
      padding-bottom: 3px;
    }

  }
}

.termsWrapper {
  text-align: center;

  & h4 {
    font-size: 28px;
    padding-bottom: 40px;
  }

  & a {
    margin-top: 30px;
  }
}

.profileApplyButton {
  width: fit-content;
  margin-top: 30px;
  color: #fff;
  border-radius: 45px;
  cursor: pointer;
  margin-top: 18px;

  & button {
    min-height: 35px;
    padding: 6px 18px;
  }
}
.applyJobModal {
&>div>div {
  @media(max-width:768px) {
    margin-top: 80px;
  }
  & button {
    @media(max-width:768px) {
      margin-top: 60px;
    }
  }
}
}