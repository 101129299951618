@import '../../../styles/customMediaQueries.css';
.root {
  position: relative;
  display: inline-block;
  width: 100%;
  & > button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100% !important;
    & > svg {
      width: 12px;
      height: 12px;
    }
  }
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  left: 0;
  right: 0;
  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  min-width: 100%;
  margin-top: 7px;
  background-color: var(--colorWhite);

  /* Borders */
  border-top: 1px solid var(--colorGrey100);
  box-shadow: var(--boxShadowPopup);
  border-radius: var(--borderRadiusMedium);
  transition: var(--transitionStyleButton);
}

.popupSize {
  padding: 10px 20px;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
