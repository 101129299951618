@import '../../../styles/customMediaQueries.css';

/* Desktop */
.fixedWidthContainer {
  composes: fixedWidthContainer from global;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: var(--boxShadowLight);
  /* border-bottom: solid 1px var(--colorBlack); */

  @media (--viewportLarge) {
    padding: 0 24px;
  }
}

.leftLinks {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;


  & .navLink {
    position: relative;
    padding: 36px 0;
    font-size: 16px;
    font-weight: var(--fontWeightRegular);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--colorBlack);
    text-decoration: none;
    line-height: 100%;
  }
}

.rightLinks {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & .navLink {
    position: relative;
    padding: 36px 0;
    font-size: 16px;
    font-weight: var(--fontWeightRegular);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--colorBlack);
    text-decoration: none;
    line-height: 100%;

    &:hover {
      color: var(--marketplaceColor);
    }

    &:not(:last-child) {
      margin-right: 28px;
    }

    &.signupLink {
      padding: 12px 24px;
      color: var(--colorWhite);
    }

    &.loginLink {
      padding: 12px 24px;
      background-color: var(--bgLight);

      &:hover {
        background-color: var(--colorBlack);
        color: var(--colorWhite);
      }

    }
  }
}

.postLink {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--colorBlack);
  text-decoration: none;
  line-height: 100%;
  margin-right: 24px;

  &:hover {
    text-decoration: none;
  }

  & svg {
    margin-bottom: 3px;
    margin-right: 2px;
  }
}

.signupLink,
.loginLink {
  composes: buttonDefault from global;
  min-width: auto;
  width: auto;
  line-height: 100%;
  min-height: auto;
}

.menuIcon {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bgLight);
  border-radius: var(--borderRadiusLarge);
  transition: var(--transitionStyle);

  &:hover {
    transition: var(--transitionStyle);
    background-color: var(--colorBlack);

    &>svg {
      & path {
        fill: var(--colorWhite);
      }
    }
  }
}

/* logo */
.logoLink {
  transition: var(--transitionStyleButton);
}

.logoLink:hover {
  border-radius: 0;
}

.inbox {
  position: relative;

  & .notificationDot {
    /* Dimensions */
    width: 7px;
    height: 7px;
    border-radius: 50%;

    /* Position */
    position: absolute;
    top: -8px;
    right: -8px;

    /* Style: red dot */
    background-color: var(--marketplaceColor);

    animation-name: notificationPop;
    animation-duration: 0.1s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;

    animation-timing-function: ease-in;
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;

  &:hover {
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 0;
  width: 45px;
  height: 45px;

  &>span {
    font-size: 18px;
  }
}

.dropdownMenu {}

.profileMenuContent {
  padding: 20px 0;
  top: calc(100% + 20px);
  right: 0;
  left: auto !important;

  &>ul {
    min-width: 276px;
  }
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.menuLink {
  composes: h4 from global;
  position: relative;
  font-weight: var(--fontWeightRegular);
  display: block;
  cursor: pointer;

  /* Dimensions */
  /* width: 100%; */
  margin: 0;
  padding: 8px 24px;

  /* Layout details */
  color: var(--colorBlack);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  composes: h4 from global;
  display: inline;
  font-weight: var(--fontWeightRegular);

  /* Text size should be inherited */
  text-decoration: none;

  /* Dimensions */
  position: relative;
  width: 100%;
  margin: 0;
  padding: 10px 24px;
  color: var(--colorBlack);

  /* Layout details */
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--colorGrey100);

  &:hover {
    cursor: pointer;
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}