@import '../../styles/customMediaQueries.css';

.root {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-end;
    height: 57px;
    align-items: flex-end;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 24px;
  &:first-child {
    margin-left: 0;
  }
}
.userNavTab {
  background-color: var(--bgLight);
  padding: 0 24px;
  & .root {
    max-width: var(--contentMaxWidth);
    width: 100%;
    margin: 0 auto;
  }
}
