@import '../../../styles/customMediaQueries.css';

.popupSize {
  padding: 0px;
}
.bookingDateRangepicker {
  & > div {
    min-width: 312px !important;
  }
}
.filterLabel,
.filterLabelSelected,
.filterLabelWrapper {
  composes: h3 from global;

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;
  padding: 4px 0 2px 0;

  @media (--viewportMedium) {
    padding: 6px 0 2px 0;
    margin-bottom: 8px;
  }
}

.filterLabel {
  color: var(--colorBlack);
}

.filterLabelSelected {
  color: var(--marketplaceColor);
}

.labelButton {
  /* Override button styles */
  width: 100%;
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.labelButtonContent {
  display: inline-block;
  width: 100%;
}

.openSign {
  float: right;
}

.clearButton {
  composes: h5 from global;
  margin: 0;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);
  padding: 2px 0 4px 12px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    padding: 4px 0 4px 12px;
  }
}

.plain {
  width: 100%;
  display: none;
}

.isOpen {
  display: block;
}
