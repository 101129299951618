@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  justify-content: center;
}

.darkSkin {
  background-color: var(--bgLight);
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.checkoutTabs {
  justify-content: flex-start;
  flex-direction: column;

  @media (min-width: 480px) {
    flex-direction: row;
  }

  & .fixedWidthContainer {
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    max-width: 100%;

    @media (min-width: 480px) {
      flex-direction: row;
    }
  }

  & .tab {
    width: 100%;
    margin-left: 0;
    margin-bottom: 10px;

    @media (min-width: 480px) {
      min-width: auto;
      width: auto;
      margin-left: 20px;
      margin-bottom: 0px;
    }

    @media (--viewportMedium) {
      width: calc(50% - 9px);

      @media (max-width: 580px) {
        width: 100%;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    & .tabContent {
      display: inline-flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      border: solid 2px var(--colorGrey100);
      background-color: var(--colorWhite);
      padding: 10px 15px;
      border-radius: 6px;
      width: 100%;
      text-align: center;
      font-size: 13px;
      color: var(--colorBlack);
      font-weight: 600;
      min-height: 100px;

      /* transition: all ease 0.5s; */
      @media (--viewportSmall) {
        font-size: 15px;
      }

      @media (--viewportMedium) {
        padding: 10px 20px;
        font-size: 16px;
      }

      & > svg {
        margin-right: 10px;
        width: 30px;
        height: 30px;
      }

      &.selectedTabContent {
        /* transition: all ease 0.5s; */
        font-weight: 600;
        border-color: var(--marketplaceColor);
      }
    }
  }
}

.tabContent {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  border: none;
  /* Font */
  composes: marketplaceTabNavHorizontalFontStyles from global;

  color: var(--colorBlack);
  padding: 10px 0;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    justify-content: center;
    border-bottom-width: 2px;
  }
}

.button {
  /* Override Chrome's border-radius which is visible when
   border is used as underline */
  border-radius: 0;
  /* Override Chrome's button outline */
  outline: 0;
}

.selectedTabContent {
  border-bottom-color: var(--marketplaceColor);
  color: var(--marketplaceColor);
}

.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}

/* Dark skin */
.tabContentDarkSkin {
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  color: var(--colorBlack);

  &:hover {
    color: var(--marketplaceColor);
  }
}

.selectedTabContentDarkSkin {
  border-bottom-color: var(--colorBlack);
  color: var(--colorBlack);
}

.disabledDarkSkin {
  color: var(--colorGrey300);
  opacity: 0.3;
}

.fixedWidthContainer {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.authContainer {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
