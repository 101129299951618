@import '../../../styles/customMediaQueries.css';
.root {
  position: relative;
  display: inline-block;
  width: 100%;
  & > button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > svg {
      width: 12px;
      height: 12px;
    }
  }
}
