.root {
  /* While the viewbox of the icon is 30x30, the icon size is scaled to
   28x28 by default, which results in scaling the whole image and the
   strokes a bit.*/
  width: 28px;
  height: 28px;

  stroke: var(--marketplaceColor);
  strokeWidth: 3px;
}
